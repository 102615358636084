import type { BuyerAgreementSettings } from './BuyerAgreement'

const state = reactive({
  settings: null as BuyerAgreementSettings | null,
})

export function useBuyerAgreementStore() {
  return {
    state: readonly(state),
    setSettings(settings: BuyerAgreementSettings) {
      state.settings = settings
    },
    clear() {
      state.settings = null
    },
  }
}

import type { BuyerAgreement, BuyerAgreementSettings, BuyerAgreementSignature } from './BuyerAgreement'
import { useBuyerAgreementStore } from './store'

export * from './BuyerAgreement'
export * from './store'

const baseSettingsURL = '/partner/buyer-agreement-settings'
const baseURL = '/partner/buyer-agreements'

export type BuyerAgreementSignatureResponse = Omit<BuyerAgreementSignature, 'createdAt'> & { createdAt: string }
export type BuyerAgreementResponse = Omit<BuyerAgreement, 'createdAt'> & {
  agentSignature: BuyerAgreementSignatureResponse
  buyerSignature: BuyerAgreementSignatureResponse
  createdAt: string
}

export function transformBuyerAgreementSignatureResponse(data: BuyerAgreementSignatureResponse): BuyerAgreementSignature {
  const signedAt = new Date(data.signedAt)
  return { ...data, signedAt }
}

export function transformBuyerAgreementResponse(data: BuyerAgreementResponse): BuyerAgreement {
  const createdAt = new Date(data.createdAt)
  const agentSignature = data.agentSignature && transformBuyerAgreementSignatureResponse(data.agentSignature)
  const buyerSignature = data.buyerSignature && transformBuyerAgreementSignatureResponse(data.buyerSignature)
  return { ...data, agentSignature, buyerSignature, createdAt }
}

export function useBuyerAgreement() {
  const store = useBuyerAgreementStore()
  const { getAgreementSettings } = useBuyerAgreementService()
  return {
    async load() {
      const settings = await getAgreementSettings()
      store.setSettings(settings)
    },
    clear() {
      store.clear()
    },
  }
}

export function useBuyerAgreementService() {
  return {
    getAgreementSettings() {
      return useAttainApi<BuyerAgreementSettings>(`${baseSettingsURL}`, { method: 'GET' })
    },
    putAgreementSettings(body: BuyerAgreementSettings) {
      return useAttainApi<BuyerAgreementSettings>(`${baseSettingsURL}`, { method: 'PUT', body })
    },
    getAgreements(query: { buyerAccountId: string }) {
      return useAttainApi<BuyerAgreementResponse[]>(`${baseURL}/`, { method: 'GET', query }).then(res => res.map(transformBuyerAgreementResponse))
    },
    createAgreement(body: { buyerAccountId: string, settings: BuyerAgreementSettings, specificSettings: BuyerAgreementSpecificSettings, isPreview?: boolean }) {
      return useAttainApi<BuyerAgreementResponse>(`${baseURL}/`, { method: 'POST', body }).then(transformBuyerAgreementResponse)
    },
    signAgreement(agreement: BuyerAgreement) {
      const { hashedContent } = agreement
      const body = { hashedContent }
      return useAttainApi<BuyerAgreementResponse>(`${baseURL}/${agreement._id}/sign`, { method: 'POST', body }).then(transformBuyerAgreementResponse)
    },
  }
}

import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as account_45validation2XLq2Uv9SBMeta } from "/opt/build/repo/pages/account-validation.vue?macro=true";
import { default as _91groupId_93mJcjKmyhyRMeta } from "/opt/build/repo/pages/chats/[groupId].vue?macro=true";
import { default as indextFgDOubWFMMeta } from "/opt/build/repo/pages/chats/index.vue?macro=true";
import { default as chatsxp08MNiZrmMeta } from "/opt/build/repo/pages/chats.vue?macro=true";
import { default as _91id_93MYJJQueMiZMeta } from "/opt/build/repo/pages/customers/[id].vue?macro=true";
import { default as indexuqTjbSei46Meta } from "/opt/build/repo/pages/customers/index.vue?macro=true";
import { default as customersyGA94d70qRMeta } from "/opt/build/repo/pages/customers.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as cardsHg9A8ZFeoxMeta } from "/opt/build/repo/pages/playground/cards.vue?macro=true";
import { default as dialogu4gHuQlzluMeta } from "/opt/build/repo/pages/playground/dialog.vue?macro=true";
import { default as formsJW9SuF5ClAMeta } from "/opt/build/repo/pages/playground/forms.vue?macro=true";
import { default as indexuLHNWYgNI7Meta } from "/opt/build/repo/pages/playground/index.vue?macro=true";
import { default as modaloQcEKE4hSqMeta } from "/opt/build/repo/pages/playground/modal.vue?macro=true";
import { default as tablesmNQUVxjBFpMeta } from "/opt/build/repo/pages/playground/tables.vue?macro=true";
import { default as uikitOxC3wo2urXMeta } from "/opt/build/repo/pages/playground/uikit.vue?macro=true";
import { default as videoc5PVask0NbMeta } from "/opt/build/repo/pages/playground/video.vue?macro=true";
import { default as playgroundM7dz7QpRYeMeta } from "/opt/build/repo/pages/playground.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as sign_45up3Rqnj2isW5Meta } from "/opt/build/repo/pages/sign-up.vue?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: account_45validation2XLq2Uv9SBMeta?.name ?? "account-validation",
    path: account_45validation2XLq2Uv9SBMeta?.path ?? "/account-validation",
    meta: account_45validation2XLq2Uv9SBMeta || {},
    alias: account_45validation2XLq2Uv9SBMeta?.alias || [],
    redirect: account_45validation2XLq2Uv9SBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/account-validation.vue").then(m => m.default || m)
  },
  {
    name: chatsxp08MNiZrmMeta?.name ?? undefined,
    path: chatsxp08MNiZrmMeta?.path ?? "/chats",
    meta: chatsxp08MNiZrmMeta || {},
    alias: chatsxp08MNiZrmMeta?.alias || [],
    redirect: chatsxp08MNiZrmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/chats.vue").then(m => m.default || m),
    children: [
  {
    name: _91groupId_93mJcjKmyhyRMeta?.name ?? "chats-groupId",
    path: _91groupId_93mJcjKmyhyRMeta?.path ?? ":groupId()",
    meta: _91groupId_93mJcjKmyhyRMeta || {},
    alias: _91groupId_93mJcjKmyhyRMeta?.alias || [],
    redirect: _91groupId_93mJcjKmyhyRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/chats/[groupId].vue").then(m => m.default || m)
  },
  {
    name: indextFgDOubWFMMeta?.name ?? "chats",
    path: indextFgDOubWFMMeta?.path ?? "",
    meta: indextFgDOubWFMMeta || {},
    alias: indextFgDOubWFMMeta?.alias || [],
    redirect: indextFgDOubWFMMeta?.redirect,
    component: () => import("/opt/build/repo/pages/chats/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: customersyGA94d70qRMeta?.name ?? undefined,
    path: customersyGA94d70qRMeta?.path ?? "/customers",
    meta: customersyGA94d70qRMeta || {},
    alias: customersyGA94d70qRMeta?.alias || [],
    redirect: customersyGA94d70qRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/customers.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93MYJJQueMiZMeta?.name ?? "customers-id",
    path: _91id_93MYJJQueMiZMeta?.path ?? ":id()",
    meta: _91id_93MYJJQueMiZMeta || {},
    alias: _91id_93MYJJQueMiZMeta?.alias || [],
    redirect: _91id_93MYJJQueMiZMeta?.redirect,
    component: () => import("/opt/build/repo/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuqTjbSei46Meta?.name ?? "customers",
    path: indexuqTjbSei46Meta?.path ?? "",
    meta: indexuqTjbSei46Meta || {},
    alias: indexuqTjbSei46Meta?.alias || [],
    redirect: indexuqTjbSei46Meta?.redirect,
    component: () => import("/opt/build/repo/pages/customers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: forgot_45password6Wrw3WyRqiMeta?.name ?? "forgot-password",
    path: forgot_45password6Wrw3WyRqiMeta?.path ?? "/forgot-password",
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    alias: forgot_45password6Wrw3WyRqiMeta?.alias || [],
    redirect: forgot_45password6Wrw3WyRqiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: playgroundM7dz7QpRYeMeta?.name ?? undefined,
    path: playgroundM7dz7QpRYeMeta?.path ?? "/playground",
    meta: playgroundM7dz7QpRYeMeta || {},
    alias: playgroundM7dz7QpRYeMeta?.alias || [],
    redirect: playgroundM7dz7QpRYeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground.vue").then(m => m.default || m),
    children: [
  {
    name: cardsHg9A8ZFeoxMeta?.name ?? "playground-cards",
    path: cardsHg9A8ZFeoxMeta?.path ?? "cards",
    meta: cardsHg9A8ZFeoxMeta || {},
    alias: cardsHg9A8ZFeoxMeta?.alias || [],
    redirect: cardsHg9A8ZFeoxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/cards.vue").then(m => m.default || m)
  },
  {
    name: dialogu4gHuQlzluMeta?.name ?? "playground-dialog",
    path: dialogu4gHuQlzluMeta?.path ?? "dialog",
    meta: dialogu4gHuQlzluMeta || {},
    alias: dialogu4gHuQlzluMeta?.alias || [],
    redirect: dialogu4gHuQlzluMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/dialog.vue").then(m => m.default || m)
  },
  {
    name: formsJW9SuF5ClAMeta?.name ?? "playground-forms",
    path: formsJW9SuF5ClAMeta?.path ?? "forms",
    meta: formsJW9SuF5ClAMeta || {},
    alias: formsJW9SuF5ClAMeta?.alias || [],
    redirect: formsJW9SuF5ClAMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/forms.vue").then(m => m.default || m)
  },
  {
    name: indexuLHNWYgNI7Meta?.name ?? "playground",
    path: indexuLHNWYgNI7Meta?.path ?? "",
    meta: indexuLHNWYgNI7Meta || {},
    alias: indexuLHNWYgNI7Meta?.alias || [],
    redirect: indexuLHNWYgNI7Meta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/index.vue").then(m => m.default || m)
  },
  {
    name: modaloQcEKE4hSqMeta?.name ?? "playground-modal",
    path: modaloQcEKE4hSqMeta?.path ?? "modal",
    meta: modaloQcEKE4hSqMeta || {},
    alias: modaloQcEKE4hSqMeta?.alias || [],
    redirect: modaloQcEKE4hSqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/modal.vue").then(m => m.default || m)
  },
  {
    name: tablesmNQUVxjBFpMeta?.name ?? "playground-tables",
    path: tablesmNQUVxjBFpMeta?.path ?? "tables",
    meta: tablesmNQUVxjBFpMeta || {},
    alias: tablesmNQUVxjBFpMeta?.alias || [],
    redirect: tablesmNQUVxjBFpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/tables.vue").then(m => m.default || m)
  },
  {
    name: uikitOxC3wo2urXMeta?.name ?? "playground-uikit",
    path: uikitOxC3wo2urXMeta?.path ?? "uikit",
    meta: uikitOxC3wo2urXMeta || {},
    alias: uikitOxC3wo2urXMeta?.alias || [],
    redirect: uikitOxC3wo2urXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/uikit.vue").then(m => m.default || m)
  },
  {
    name: videoc5PVask0NbMeta?.name ?? "playground-video",
    path: videoc5PVask0NbMeta?.path ?? "video",
    meta: videoc5PVask0NbMeta || {},
    alias: videoc5PVask0NbMeta?.alias || [],
    redirect: videoc5PVask0NbMeta?.redirect,
    component: () => import("/opt/build/repo/pages/playground/video.vue").then(m => m.default || m)
  }
]
  },
  {
    name: reset_45password4iBG212Ge4Meta?.name ?? "reset-password",
    path: reset_45password4iBG212Ge4Meta?.path ?? "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    alias: reset_45password4iBG212Ge4Meta?.alias || [],
    redirect: reset_45password4iBG212Ge4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45up3Rqnj2isW5Meta?.name ?? "sign-up",
    path: sign_45up3Rqnj2isW5Meta?.path ?? "/sign-up",
    meta: sign_45up3Rqnj2isW5Meta || {},
    alias: sign_45up3Rqnj2isW5Meta?.alias || [],
    redirect: sign_45up3Rqnj2isW5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/sign-up.vue").then(m => m.default || m)
  }
]